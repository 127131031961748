import React, { useState } from "react";
import cheerio from "cheerio";
import { Button, Grid, Text, Input, useToasts, Card } from "@geist-ui/core";
import "./App.css";

function App() {
  const [url, setUrl] = useState("");
  const [loading, setLoading] = useState(false);

  const { setToast: Toast } = useToasts();

  const handleDownload = async () => {
    if (!url) {
      Toast({ text: "网站不正确，再检查下～", type: "warning" });
      return;
    }
    const response = await fetch(url.trim());
    if (response.status !== 200) {
      Toast({ text: "网站不正确，再检查下～", type: "warning" });
      return;
    }

    setLoading(true);
    const $ = cheerio.load(await response.text());
    const filename = $("title").text().split("-")[0].trim();
    const date = $(".view_mess_list .double span").first().text().replace(/-/gi, "/");

    const urls = [...Array(26).keys()]
      .map((i) => {
        return [...Array(26).keys()].map((j) => {
          return String.fromCharCode(97 + i) + String.fromCharCode(97 + j);
        });
      })
      .flat()
      .map((item) => {
        const baseUrl = `https://download.wanyiwang.com/${date}/${item}${encodeURIComponent(filename)}`;

        return baseUrl;
      });

    const res = await Promise.all(
      urls.map(async (url) =>
        fetch(url)
          .then((res) => res.status)
          .catch((error) => {})
      )
    );
    setLoading(false);
    const realFile = urls[res.indexOf(200)];
    if (!realFile) {
      Toast({ text: `下载不了了～`, type: "warning" });
      setLoading(false);
      return;
    }

    fetch(realFile)
      .then((response) => response.blob())
      .then((url) => {
        const a = document.createElement("a");
        a.href = window.URL.createObjectURL(url);
        a.download = filename;
        a.click();

        Toast({ text: `下载成功!`, type: "success" });
        setLoading(false);
      })
      .catch((error) => {
        Toast({ text: `下载不了了～`, type: "warning" });
        setLoading(false);
      });
  };

  const handler = (e: any) => {
    setUrl(e.target.value);
  };

  return (
    <div
      style={{
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        display: "flex",
        width: "380px",
        margin: "-40px auto",
      }}
    >
      <Grid>
        <Card hoverable>
          <Text h3>资料下载</Text>
          <Grid.Container gap={2} justify="space-between" alignContent="center">
            <Grid xs={24} md={24}>
              <Input style={{ width: "300px" }} clearable onChange={handler} placeholder="请输入需要下载的网站" />
            </Grid>
            <Grid xs={24} md={24}>
              <Button auto type="secondary" disabled={loading} onClick={handleDownload}>
                {loading ? "下载中..." : "下载"}
              </Button>
            </Grid>
          </Grid.Container>
          <Text font="12px" mt={2} style={{ color: "#9e9e9e" }}>
            Powered by 哆啦A梦
          </Text>
        </Card>
      </Grid>
    </div>
  );
}

export default App;
