import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { GeistProvider, CssBaseline } from "@geist-ui/core";

ReactDOM.render(
  <GeistProvider>
    <CssBaseline />
    <App />
  </GeistProvider>,
  document.getElementById("root")
);
